.home {
  pointer-events: initial;
  width: 100vw;
  min-height: 100vh;
  background-color: #253232;
  background-image: url("../../assets/img/bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-position-y: -2%;
  background-size: 130%;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .row_one {
      margin-top: 0.5rem;

      img {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .row_sec {
      margin-top: 0.36rem;

      span {
        color: #fff;
        font-family: Noto Naskh Arabic;
        font-size: 0.6rem;
        font-weight: normal;
        // text-align: right;
        letter-spacing: 0em;
        // direction: rtl;
        // unicode-bidi: embed;
      }
    }

    .row_three {
      margin-top: 0.2rem;

      span {
        font-family: Noto Naskh Arabic;
        font-size: 0.36rem;
        font-weight: normal;
        // text-align: right;
        letter-spacing: 0em;
        color: #7c7c80;
        // direction: rtl;
        // unicode-bidi: embed;
      }
    }

    .row_four {
      margin-top: 0.4rem;
      display: flex;
      flex-direction: row;
      gap: 0.4rem;

      .button_chee {
        display: flex;
        flex-direction: column;
        padding: 0.28rem;
        box-sizing: border-box;
        border: 6px solid #36605e;
        border-radius: 0.32rem;

        font-family: Noto Naskh Arabic;
        font-size: 0.32rem;
        font-weight: normal;
        text-align: right;
        letter-spacing: 0em;
        color: #ffffff;
        // direction: rtl;
        // unicode-bidi: embed;
      }
    }

    .row_five {
      display: flex;
      flex-direction: row;
      gap: 0.4rem;
      margin-top: 1.43rem;
      cursor: pointer;

      .down_btn {
        border-radius: 0.32rem;
        background: #ffffff;
        width: 3.73rem;
        height: 1rem;
        line-height: 1rem;

        font-family: Noto Naskh Arabic;
        font-size: 0.32rem;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #0c2432;

        span {
          // direction: rtl;
          // unicode-bidi: embed;
        }

        img {
          vertical-align: middle;
          width: 0.44rem;
          height: 0.44rem;
        }
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0.1rem;
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 0.12rem;
      line-height: 1.4;
      font-family: "HSSMedium";

      .left {
        .nav {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1.2rem;

          .item {
            font-family: Noto Naskh Arabic;
            font-size: 0.24rem;
            font-weight: normal;
            letter-spacing: 0em;
            text-decoration: underline;
            color: #ffffff;

            // direction: rtl;
            // unicode-bidi: embed;
          }
        }
      }
    }
  }
}