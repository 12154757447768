.locales-btn {
  position: absolute;
  right: 0.6rem;
  top: 0.5rem;
  display: flex;
  direction: ltr;
  unicode-bidi: normal;

  &-item {
    padding: 0.12rem 0.24rem;
    cursor: pointer;
    border: 2px solid #36605e;
    color: #fff;
    font-family: Noto Naskh Arabic;
    font-size: 18px;
    border-radius: 16px;

    &:not(:last-of-type) {
      margin-right: 0.16rem;
    }

    &--active {
      background-color: #36605e;
    }
  }
}

@media (max-width: 640px) {
  .locales-btn {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0.3rem;
    padding: 0 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .locales-btn-item {
      width: 1rem;
      margin: 0.1rem 0 0 0;
      white-space: nowrap;
      height: 0.5rem;
    }
  }
}
