.mobileHome {
  width: 100%;
  // padding-top: 0.6rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #253232;
  background-image: url("../../assets/img/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 3.9rem;
  background-position-x: center;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .row_one {

      // margin-top: 1rem;
      img {
        width: 1rem;
        height: 1rem;
      }
    }

    .row_sec {
      text-align: center;
      margin-top: 0.1rem;

      span {
        color: #fff;
        font-family: Noto Naskh Arabic;
        font-size: 0.24rem;
        font-weight: normal;
        letter-spacing: 0em;

      }
    }

    .row_three {
      text-align: center;

      span {
        font-family: Noto Naskh Arabic;
        font-size: 0.12rem;
        font-weight: normal;
        letter-spacing: 0em;
        color: #7c7c80;

      }
    }

    .row_four {
      margin-top: 0.2rem;
      display: flex;
      flex-direction: row;
      gap: 0.05rem;

      .button_chee {
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        box-sizing: border-box;
        border: 3px solid #36605e;
        border-radius: 0.16rem;

        font-family: Noto Naskh Arabic;
        font-size: 0.1rem;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #ffffff;
      }
    }

    .row_five {
      margin-top: 0.4rem;

      .down_btn {
        border-radius: 0.16rem;
        background: #ffffff;
        width: 1.865rem;
        height: 0.5rem;
        line-height: 0.5rem;

        font-family: Noto Naskh Arabic;
        font-size: 0.16rem;
        font-weight: normal;
        text-align: center;
        letter-spacing: 0em;
        color: #0c2432;

      }
    }
  }

  .footer {
    width: 100%;
    position: fixed;
    bottom: 0.3rem;

    .container {
      .left {
        .nav {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.1rem;

          .item {
            font-family: Noto Naskh Arabic;
            font-size: 0.14rem;
            font-weight: normal;
            letter-spacing: 0em;
            text-decoration: underline;
            color: #ffffff;
          }
        }
      }
    }
  }
}