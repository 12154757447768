/* CONTAINER */

#pcontainer {
  font-family: LarkHackSafariFont, LarkEmojiFont, LarkChineseQuote,
    -apple-system, BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC,
    Microsoft Yahei, Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  background-color: #f7f8f9;
  // padding-top:5%;
  padding-bottom: 5%;
  padding-left: 20%;
  padding-right: 20%;
  color: #000;
  min-height: calc(100vh - 90px);

  span {
    font-weight: normal;
    font-size: 1em;
    // text-align: right;
    line-height: 1.5;
    margin: auto;
    color: rgb(0, 0, 0);
  }

  .h0 {
    font-size: 1em;
  }

  p {
    font-weight: 300;
    font-size: 0.7em;
    // text-align: right;
    line-height: 1.5;
    margin: auto;
    color: rgba(0, 0, 0, 0.693);
  }

  h1 {
    font-weight: bold;
    font-size: 0.5em;
    margin-block-end: 1em;
    font-family: LarkHackSafariFont, LarkEmojiFont, LarkChineseQuote,
      -apple-system, BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC,
      Microsoft Yahei, Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    margin-top: 0 !important;
  }

  a {
    text-decoration: none;
    color: #3cb2e2;
  }

  .section {
    background-color: white;
    border-radius: 15px;
    // padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;

    p {
      font-weight: 300;
      font-size: 0.7em;
      // text-align: right;
      line-height: 1.5;
      margin: auto;
      color: rgba(0, 0, 0, 0.693);
    }
  }
}


@media (max-width: 640px) {

  /* SUR MOBILE */
  #pcontainer {
    font-family: LarkHackSafariFont, LarkEmojiFont, LarkChineseQuote,
      -apple-system, BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC,
      Microsoft Yahei, Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji,
      Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    background-color: #f7f8f9;
    // padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;

    span {
      // font-family: "avenir-black";
      font-size: 0.9em;
      line-height: 1.3;
      margin: auto;
    }

    p {
      // font-family: "avenir-light";
      font-size: 0.5em;
      line-height: 1.3;
      margin: auto;
    }

    h1 {
      // font-family: "avenir-black";
      font-size: 0.5em;
    }

    .section {
      background-color: white;
      border-radius: 10px;
      padding-top: 3%;
      padding-bottom: 3%;
      padding-left: 3%;
      padding-right: 3%;

      p {
        font-size: 0.5em;
        line-height: 1.3;
        margin: auto;
      }
    }
  }
}

/* FOOTER */

/*#footer p {
color:#9b9b9b;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-roman';
text-decoration:none;
}*/