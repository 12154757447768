#pcontainer {
  font-family: LarkHackSafariFont, LarkEmojiFont, LarkChineseQuote,
    -apple-system, BlinkMacSystemFont, Helvetica Neue, Tahoma, PingFang SC,
    Microsoft Yahei, Arial, Hiragino Sans GB, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  background-color: #f7f8f9;
  // padding-top:5%;
  padding-bottom: 5%;
  padding-left: 15%;
  padding-right: 15%;
  color: #000;

  .h0 {
    font-size: 1em;
  }

  span {
    font-weight: normal;
    font-size: 1em;
    line-height: 1.5;
    margin: auto;
    color: rgb(0, 0, 0);
  }

  .images {
    width: 350px;
    box-shadow: 0px 0px 6px gainsboro;
    border-radius: 10px;
  }

  p {
    font-weight: 300;
    font-size: 0.7em;
    line-height: 1.5;
    margin: auto;
    color: rgba(0, 0, 0, 0.693);
  }

  h1 {
    font-weight: bold;
    font-size: 0.5em;
    margin-block-start: 0;
    margin-block-end: 1em;
  }

  a {
    text-decoration: none;
    color: #3cb2e2;
  }

  // #email {
  //   text-decoration: none;
  //   color: #3cb2e2;
  // }
  .section {
    background-color: white;
    border-radius: 15px;
    // padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;

    ul {
      padding: 0 20px;
    }
  }
}

@media (max-width: 640px) {

  /* SUR MOBILE */
  #pcontainer {
    background-color: #f7f8f9;
    // padding-top: 5%;
    padding-bottom: 5%;
    padding-left: 5%;
    padding-right: 5%;

    span {
      font-family: "avenir-black";
      font-size: 0.9em;
      line-height: 1.3;
      margin: auto;
    }

    p {
      font-family: "avenir-light";
      font-size: 0.5em;
      line-height: 1.3;
      margin: auto;
    }

    h1 {
      font-family: "avenir-black";
      font-size: 0.5em;
    }

    h2 {
      font-family: "avenir-black";
      font-size: 0.9em;
    }

    .section {
      background-color: white;
      border-radius: 10px;
      padding-top: 3%;
      padding-bottom: 3%;
      padding-left: 3%;
      padding-right: 3%;
    }
  }

}

/* FOOTER */

/*#footer p {
color:#9b9b9b;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-roman';
text-decoration:none;
}*/