
.rules {
    background: #F7F8FA;
    padding: 16px 14px 0;
}
.rules-content {
  font-size: 24px;
  padding: 40px;
  color: #000;
  background: #fff;
  padding: 12px;
}

.rules-content-section {
        margin: 0 0 24px 0;
    }
    .rules-content-title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
    }

    .rules-content-desc{
        font-family: Barlow;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        color: #111111;
    }

    .rules-content-img{
        width: 323px;
        height: 558px;
    }

