.chat-history {
  margin: 0 auto;
  background-color: #000;
  min-height: 100vh;
  color: #fff;
  width: 100vw;
  max-width: 3.75rem;
  font-family: Noto Naskh Arabic;
  padding-bottom: 1rem;

  &-text {
    box-sizing: border-box;
    // width: 2.99rem;
    flex: 1;
    min-height: 0.1rem;
    font-size: 0.14rem;
    word-break: break-all;
    padding: 0 0.16rem;
  }

  &-me {
    display: flex;
    padding: 0.16rem;
    justify-content: space-around;

    &-icon {
      width: 0.32rem;
      height: 0.32rem;
    }
  }

  &-ai {
    display: flex;
    background: #304e4d;
    justify-content: space-around;
    padding: 0.16rem;
  }

  &-btn {
    position: fixed;
    bottom: 0.36rem;
    padding: 0 0.16rem;

    &-main {
      width: 3.43rem;
      height: 0.48rem;
      border-radius: 0.16rem;
      background: #5ca7a5;
      color: #fff;
      font-size: 0.16rem;
      text-align: center;
      line-height: 0.48rem;
    }
  }
}
