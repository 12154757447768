


/* CONTAINER */

#pcontainer {
background-color: #F7F8F9;
padding-top:5%;
padding-bottom:5%;
padding-left:20%;
padding-right:20%;
}


.okx-img{
    max-width: 320px;
    margin: 10px auto;
    width: 100%;
  }


.images {
width: 350px;
box-shadow: 0px 0px 6px gainsboro;
border-radius: 10px;
}


@media (max-width: 640px) { /* SUR MOBILE */
#pcontainer {
background-color: #F7F8F9;
padding-top:5%;
padding-bottom:5%;
padding-left:5%;
padding-right:5%;
}
}


body {
margin:0px;
padding:0px;
}


span {
font-family:'avenir-black';
font-size: 1.0em;
text-align: left;
line-height:1.5;
margin:auto;
}


@media (max-width: 640px) { /* SUR MOBILE */
span {
font-family:'avenir-black';
font-size: 0.9em;
text-align: left;
line-height:1.3;
margin:auto;
}
}


p {
font-family:'avenir-light';
font-size: 1.0em;
text-align: left;
line-height:1.5;
margin:auto;
}


@media (max-width: 640px) { /* SUR MOBILE */
p {
font-family:'avenir-light';
font-size: 0.9em;
text-align: left;
line-height:1.3;
margin:auto;
}
}


h1 {
font-family:'avenir-black';
font-size: 1.6em;
}


@media (max-width: 640px) {
h1 {
font-family:'avenir-black';
font-size: 1.1em;
}
}


h2 {
font-family:'avenir-black';
font-size: 1.0em;
}


@media (max-width: 640px) {
h2 {
font-family:'avenir-black';
font-size: 0.9em;
}
}


#regles {
text-decoration: none;
color: #3cb2e2;
}

#email {
text-decoration: none;
color: #3cb2e2;
}


.section {
background-color: white;
border-radius:15px;
padding-top:2%;
padding-bottom:2%;
padding-left:2%;
padding-right:2%;
}


@media (max-width: 640px) { /* SUR MOBILE */
.section {
background-color: white;
border-radius:10px;
padding-top:3%;
padding-bottom:3%;
padding-left:3%;
padding-right:3%;
}
}


/* FOOTER */


#footer {
background-color: white;
text-align: center;
padding-top:24px;
padding-bottom:24px;
padding-left:10px;
padding-right:10px;
border-top:solid 1px whitesmoke;
}


/*#footer p {
color:#9b9b9b;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-roman';
text-decoration:none;
}*/


#footer a {
color:#262626;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:none;
}


#footer a:hover {
color:#9c9c9c;
}


@media (max-width: 640px) { /* SUR MOBILE */
#footer a {
color:#262626;
line-height:2.5;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:none;
}
}




