.footer-all {
    background-color: white;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: solid 1px whitesmoke;

    a {
        color: #262626;
        line-height: 1.6;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 10px;
        font-family: "avenir-black";
        text-decoration: none;

        &:hover {
            color: #9c9c9c;
        }
    }

}

@media (max-width: 640px) {
    .footer-all {
        a {
            color: #262626;
            line-height: 2.5;
            font-size: 12px;
            margin-left: 10px;
            margin-right: 10px;
            font-family: "avenir-black";
            text-decoration: none;
        }
    }
}